import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import htmx from "htmx.org";

import { DarkModeController } from "./controllers/DarkModeController";
import { HeaderController } from "./controllers/HeaderController";
import { CarouselController } from "./controllers/CarouselController";
import { ModalController } from "./controllers/ModalController";
import { TabController } from "./controllers/TabController";
import { LightboxController } from "./controllers/LightboxController";
import { ToggleController } from "./controllers/ToggleController";

// Инициализация Stimulus
const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

// Регистрация контроллеров
application.register("dark-mode", DarkModeController);
application.register("header", HeaderController);
application.register("toggle", ToggleController);
application.register("tabs", TabController);
application.register("modal", ModalController);
application.register("carousel", CarouselController);
application.register("lightbox", LightboxController);

window.htmx = htmx;
