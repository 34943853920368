import { Controller } from '@hotwired/stimulus';

export class DarkModeController extends Controller {
  static targets = ['toggle'];

  connect() {
    this.loadTheme();
  }

  toggleTheme() {
    const darkMode = this.toggleTarget.checked;
    this.setTheme(darkMode);
  }

  loadTheme() {
    const savedTheme = localStorage.getItem('theme');

    if (savedTheme) {
      document.documentElement.classList.toggle('dark', savedTheme === 'dark');
    } else {
      document.documentElement.classList.toggle(
        'dark',
        window.matchMedia('(prefers-color-scheme: dark)').matches,
      );
    }
  }

  setTheme(darkMode) {
    document.documentElement.classList.toggle('dark', darkMode);

    localStorage.setItem('theme', darkMode ? 'dark' : 'light');
  }
}
