import { Controller } from '@hotwired/stimulus';

export class ToggleController extends Controller {
  static targets = ['toggleable'];
  static values = {
    open: { type: Boolean, default: false }
  };

  connect() {
    console.log('ToggleController connected');
    this.updateState();
  }

  toggle(event) {
    console.log('Toggling');
    this.openValue = !this.openValue;
    this.updateState();
  }

  toggleInput(event) {
    console.log('Toggling input', event.target.checked);
    this.openValue = event.target.checked;
    this.updateState();
  }

  hide() {
    console.log('Hiding');
    this.openValue = false;
    this.updateState();
  }

  show() {
    console.log('Showing');
    this.openValue = true;
    this.updateState();
  }

  updateState() {
    console.log('Updating state', this.openValue);
    this.toggleableTargets.forEach(target => {
      if (this.openValue) {
        target.classList.remove('hidden');
      } else {
        target.classList.add('hidden');
      }
    });
  }
}
